const initialState = {
  exchangeCoinsRate: {
    buy_market_rate: 0,
    sell_market_rate: 0,
    deposit_items_rate: 0,
    deposit_crypto_rate: 0,
    withdraw_crypto_rate: 0,
  }
};

const siteSettingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_EXCHANGE_COINS_RATES":     
      return {
        ...state,
        exchangeCoinsRate: payload,
      };
    default:
      return state;
  }
};

export default siteSettingsReducer;